import {useCallback, useEffect, useRef} from "react";

// const useIsMounted = () => {
//     const [isMounted, setIsMounted] = useState(false);
//
//     useEffect(() => {
//         setIsMounted(true);
//         return () => setIsMounted(false);
//     }, []);
//
//     return isMounted;
// };

const useIsMounted = () => {
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, [])

    const checker = useCallback(() => {
        return isMounted.current
    }, []);

    return checker;
}

export default useIsMounted;
