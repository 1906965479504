import {useRedPill} from "Hooks/api/useRedPill";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {updateMessagesCount, updateMessagesList} from "Redux/actions/messages";
import {IS_MOBILE} from "Setup/config";

const messagePath = "authorizedPaths.messages";

function useCountMessages() {
    const dispatch = useDispatch();
    const {execute, status, value, error} = useRedPill(`${messagePath}.count`);

    const countMessages = async () => {
        const res = await execute();

        try {
            if (res.code === 200) {
                const messagesCount = res.data['x-total-count'];
                const unreadMessagesCount = res.data['x-total-unread-count'];

                dispatch(updateMessagesCount(messagesCount, unreadMessagesCount));
                return true;
            }
        } catch (e) {
            //sendExceptionToSentry(e);
            console.log(e);
        }

        return false;
    };

    return {countMessages, status, value, error};
}

function useGetMessage() {
    const {countMessages} = useCountMessages();
    const {execute, status, value, error} = useRedPill(`${messagePath}.get`);

    const getMessage = async (messageId) => {
        const res = await execute({url: `/${messageId}`});

        try {
            if (res.code === 200) {
                const count = countMessages();
                return res.data;
            }
        } catch (e) {
            //sendExceptionToSentry(e);
            console.log(e);
        }

        return false;
    };

    return {getMessage, status, value, error};
}


export const MAX_MESSAGES_PER_PAGE = IS_MOBILE ? 5 : 8;

function useListMessages() {
    const dispatch = useDispatch();
    const {execute, status, value, error} = useRedPill(`${messagePath}.list`);

    const listMessages = async ({page = 0, take = MAX_MESSAGES_PER_PAGE} = {}) => {
        const skip = page > 0 ? (page - 1) * MAX_MESSAGES_PER_PAGE : 0;
        const res = await execute({url: `?take=${take}&skip=${skip}`});

        try {
            if (res.code === 200) {
                dispatch(updateMessagesList(res.data.items));
                return true;
            }
        } catch (e) {
            //sendExceptionToSentry(e);
            console.log(e);
        }

        return false;
    };

    return {listMessages, status, value, error};
}

function useCategorySpecificMessages() {
    const {execute, status, value, error} = useRedPill(`${messagePath}.list`);

    const listCategoryMessages = async (category) => {
        const res = await execute({url: `?category=${category}`});

        try {
            if (res.code === 200) {
                return res.data;
            }
        } catch (e) {
            //sendExceptionToSentry(e);
            console.log(e);
        }

        return false;
    };

    return {listCategoryMessages, status, value, error};
}

function useDeleteMessage() {
    const {execute, status, value, error} = useRedPill(`${messagePath}.delete`);
    const {listMessages} = useListMessages();

    const deleteMessage = async (messageId, refreshListOnComplete = true) => {
        const res = await execute({url: `/${messageId}`});

        try {
            if (res.code === 200) {
                if (refreshListOnComplete) {
                    await listMessages();
                }
                return true;
            }
        } catch (e) {
            //sendExceptionToSentry(e);
            console.log(e);
        }

        return false;
    };

    return {deleteMessage, status, value, error};
}

export const useMessages = () => {
    const {messagesCount, unreadMessagesCount, messages, messagesLoaded} = useSelector(state => ({
        messagesCount: state.messages.messagesCount,
        unreadMessagesCount: state.messages.unreadMessagesCount,
        messages: state.messages.list,
        messagesLoaded: state.messages.isLoaded
    }), shallowEqual)

    return {
        messages,
        messagesLoaded,
        messagesCount,
        unreadMessagesCount,
        _count: useCountMessages,
        _get: useGetMessage,
        _list: useListMessages,
        _category: useCategorySpecificMessages,
        _delete: useDeleteMessage,
    };
};
