import {APP_VERSION, BRAND_NAME, IS_DEV, IS_STAGING, PLAYER_ID} from "Setup/config";

export const sendLogglyEvent = (category, message, json = {}) => {
    try {
        if (typeof _LTracker !== 'undefined') {
            json = {
                ...json,
                playerId: PLAYER_ID,
                site: BRAND_NAME,
                version: APP_VERSION,
                environment: IS_DEV ? 'local' : IS_STAGING ? 'staging' : 'production'
            };

            _LTracker.push({
                category,
                message,
                json
            });
        } else {
            // console.log('_LTracker() not defined');
        }
    } catch (e) {
        console.log(e);
    }
};
