const getConsentValueById = (id) => {
    try {
        const determineValue = (s) => (s == undefined ? "" : s ? "granted" : "denied");

        // Check if google_tag_data exists
        if (!window["google_tag_data"]) {
            console.warn("No Consent Mode data found");
            return;
        }

        // Retrieve entries from google_tag_data or set to null
        const consentEntries = "ics" in google_tag_data ? google_tag_data.ics.entries : null;
        let defaultStatus = "";
        let updateStatus = "";

        for (let key in consentEntries) {
            if (key === id) {
                defaultStatus = determineValue(consentEntries[key]['default']);
                updateStatus = determineValue(consentEntries[key]['update']);

                if (updateStatus) return updateStatus;
                if (defaultStatus) return defaultStatus;

                // Skip if both default and update statuses are empty
                if (defaultStatus === "" && updateStatus === "") return "denied";
            }
        }
    } catch (e) {
        console.log(e);
    }

    return null;
}

export default getConsentValueById;