import {useRedPill} from "Hooks/api/useRedPill";
import {useState} from "react";
import {useSelector} from "react-redux";
import {IS_LOGGED_IN, IS_QUICK_BANKING_ENABLED, IS_SLIM_BANKING_ENABLED, PLAYER_ID} from "Setup/config";
import {sendLogglyEvent} from "Helpers/loggly";

export default function useBanking({quickBanking = false, promoCode = false, specificAmount = false, slimBanking = false} = {}) {
    const [bankingErrorCode, setBankingErrorCode] = useState(null);

    let bp;
    let bankingPath = "authorizedPaths.banking";

    if (promoCode) {
        bp = `${bankingPath}.launchv2WithBonus`;

        if (quickBanking && IS_QUICK_BANKING_ENABLED) {
            bp = `${bankingPath}.launchv2WithBonusQuick`;
        }

        if (slimBanking && IS_SLIM_BANKING_ENABLED) {
            bp = `${bankingPath}.launchv2slimWithBonus`;
            if (specificAmount) {
                bp = `${bankingPath}.launchv2slimWithBonusAndAmount`;
            }
        }
    } else {
        bp = `${bankingPath}.launchv2`;

        if (quickBanking && IS_QUICK_BANKING_ENABLED) {
            bp = `${bankingPath}.launchv2Quick`
        }

        if (slimBanking && IS_SLIM_BANKING_ENABLED) {
            bp = `${bankingPath}.launchv2slim`;
        }
    }

    const {execute, status, value, error} = useRedPill(bp);

    const launchBanking = async () => {
        const sendMerchantUniqueIdToLoggly = true;
        setBankingErrorCode(null);

        const headers = {'Cache-Control': 'no-cache, no-store, must-revalidate'};
        let additionalUrl = promoCode ? promoCode : '';

        // If launching slim banking and there's a specific amount to use, add it to the request
        if (IS_SLIM_BANKING_ENABLED && slimBanking && specificAmount) {
            additionalUrl = `${additionalUrl}/${specificAmount}`;
        }

        const res = await execute({url: additionalUrl, additionalHeaders: headers});

        try {
            if (res.code === 200) {
                if (sendMerchantUniqueIdToLoggly) {
                    try {
                        let url = new URL(res.data.launchUrl);
                        let urlSearch = url.search;
                        let ps = new URLSearchParams(urlSearch);

                        for (let [k, v] of ps) {
                            if (k === "merchant_unique_id") {
                                sendLogglyEvent('Safecharge', `Tracking merchant_unique_id`, {merchant_unique_id: v, playerId: PLAYER_ID});
                            }
                        }
                    } catch (e) {
                        sendLogglyEvent('Safecharge', `Error sending merchant_unique_id`);
                    }
                }

                return res.data;
            }

            if (res?.data?.errors?.message) {
                setBankingErrorCode(res.data.errors.message);
            }
        } catch (e) {
            //sendExceptionToSentry(e);
            console.log(e);
        }

        return false;
    };

    const {hasDeposited, isEligibleForQuickBanking} = useSelector(state => {
        let hasDeposited = false;
        let isEligibleForQuickBanking = false;

        if (IS_LOGGED_IN) {
            try {
                hasDeposited = state.morpheus.account.hasDeposited;
                isEligibleForQuickBanking = state.morpheus.account.quickBanking;
            } catch (e) {
                //sendExceptionToSentry(e);
                console.log(e);
            }
        }

        return {
            hasDeposited,
            isEligibleForQuickBanking
        }
    });

    return {launchBanking, hasDeposited, bankingErrorCode, isEligibleForQuickBanking, status, value, error};
}
