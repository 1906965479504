import {UPDATE_ACCOUNT_LITE_ACTIVE, UPDATE_HAS_DEPOSITED, UPDATE_HAS_QUICK_BANKING, UPDATE_MORPHEUS_SESSION, UPDATE_USER_COMMUNICATION_PREFERENCES} from "Redux/actions/morpheus";

const morpheusReducer = (state = {isLoaded: true, isAccountLiteActive: false, ...window.MORPHEUS}, action) => {
    let newSession;

    switch (action.type) {
        case UPDATE_MORPHEUS_SESSION:
            return {...state, isLoaded: true, ...action.newMorpheusSession};
        case UPDATE_ACCOUNT_LITE_ACTIVE:
            return {...state, isAccountLiteActive: action.isActive};
        case UPDATE_USER_COMMUNICATION_PREFERENCES:
            newSession = Object.assign({}, state);

            if (newSession?.personal?.marketing) {
                newSession.personal.marketing = Object.assign({}, newSession.personal.marketing, {...action.newComms});
            }

            return {...newSession};
        case UPDATE_HAS_DEPOSITED:
            newSession = Object.assign({}, state);

            if (newSession?.account) {
                newSession.account.hasDeposited = true;
            }

            return {...newSession};
        case UPDATE_HAS_QUICK_BANKING:
            newSession = Object.assign({}, state);

            if (newSession?.account) {
                newSession.account.quickBanking = action.quickBanking;
            }

            return {...newSession};
        default:
            return {...state};
    }
};

export default morpheusReducer;
