// Action Types
export const UPDATE_THEME = "UPDATE_THEME";
export const TOGGLE_DRAWER = "TOGGLE_DRAWER";
export const TOGGLE_PANEL = "TOGGLE_PANEL";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const CLOSE_ALL_MODALS = "CLOSE_ALL_MODALS";
export const UPDATE_FREE_SPINS_SHUFFLE_STATUS = "UPDATE_FREE_SPINS_SHUFFLE_STATUS";
export const UPDATE_LOBBY_BANNERS_LIST = "UPDATE_LOBBY_BANNERS_LIST";
export const UPDATE_REWARDS_LIST = "UPDATE_REWARDS_LIST";
export const UPDATE_SPECIAL_EVENT_PROMO_LIST = "UPDATE_SPECIAL_EVENT_PROMO_LIST";
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";

// Action Creator
export const updateTheme = (newTheme) => ({
    type: UPDATE_THEME,
    newTheme,
});

export const toggleDrawer = (drawer, isOpen) => {
    return {
        type: TOGGLE_DRAWER,
        drawer,
        isOpen
    }
};

export const toggleNotificationsPanel = () => {
    return {
        type: TOGGLE_PANEL,
        panel: 'notifications'
    }
};

export const openModal = (modalId, modalProps, clearExistingModals) => {
    return {
        type: OPEN_MODAL,
        modalId,
        modalProps,
        clearExistingModals
    }
}

export const closeModal = (modalId) => {
    return {
        type: CLOSE_MODAL,
        modalId
    }
}

export const closeAllModals = () => {
    return {
        type: CLOSE_ALL_MODALS,
    }
}

export const updateFreeSpinsShuffleStatus = (data) => {
    return {
        type: 'UPDATE_FREE_SPINS_SHUFFLE_STATUS',
        data: {...data}
    }
};

export const updateLobbyBannersList = (data) => {
    return {
        type: UPDATE_LOBBY_BANNERS_LIST,
        data
    }
};

export const updateRewardsList = (data) => {
    return {
        type: UPDATE_REWARDS_LIST,
        data
    }
};

export const updateSpecialEventPromoList = (mastergroup, data) => {
    return {
        type: UPDATE_SPECIAL_EVENT_PROMO_LIST,
        mastergroup,
        data
    }
};

export function setLoginError(errorMessage = false) {
    if (!errorMessage) errorMessage = "We couldn't log you in. Please check your username and password are correct and try again.";

    return {
        type: SET_LOGIN_ERROR,
        errorMessage
    }
}

export function clearLoginError() {
    return {
        type: CLEAR_LOGIN_ERROR
    }
}
