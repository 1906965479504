// Action Types
export const UPDATE_LOBBY = "UPDATE_LOBBY";
export const FETCH_LOBBY_FAIL = "FETCH_LOBBY_FAIL";
export const FETCH_LOBBY = "FETCH_LOBBY";
export const UPDATE_RECENT_GAMES = "UPDATE_RECENT_GAMES";

// Action Creator
export const updateLobby = (newLobby) => ({
    type: UPDATE_LOBBY,
    newLobby,
});

export const fetchLobbyFail = () => ({
    type: FETCH_LOBBY_FAIL,
});

export const fetchLobby = () => ({
    type: FETCH_LOBBY,
});

export const updateRecentGames = (newRecentGames) => ({
    type: UPDATE_RECENT_GAMES,
    newRecentGames,
});
