import {useEffect, useRef} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useRedPill} from "Hooks/api/useRedPill";
import {useMessages} from "Hooks/api/useMessages";
import usePromotions from "Hooks/api/usePromotions";
import {updatePlayerWallets, updatePlayerWalletsStatus} from "Redux/actions/wallets";
import {updatePlayerLimits} from "Redux/actions/limits";
import {updatePlayerClaims, updatePlayerClaimsStatus} from "Redux/actions/claims";
import {IS_LOGGED_IN} from "Setup/config";
import {setHasQuickBanking, updateAccountLiteActive} from "Redux/actions/morpheus";
import {updateLoyaltyPointsConfig, updatePlayerLoyaltyPoints} from "Redux/actions/loyalty";
import {useSnackbar} from "notistack";
import {updateMessagesCount} from "Redux/actions/messages";
import useFreeSpins from "Hooks/useFreeSpins";

const ACCOUNT_REFRESH_INTERVAL = 1000 * 30;
const PROMOTIONS_REFRESH_INTERVAL = 1000 * 60 * 10;

export function useAccount() {
    let accountTimer = null;
    let promotionsTimer = null;

    const dispatch = useDispatch();
    const {_list: _listPromotions, _history} = usePromotions();
    const {_count} = useMessages();
    const {listPromotions} = _listPromotions();
    const {listUsersActivePromotions} = _history();
    const {countMessages} = _count();
    const {_getFreeSpins: getFreeSpins} = useFreeSpins();

    const getPath = 'authorizedPaths.myAccountObj.get';
    const {execute, status, value, error} = useRedPill(getPath);

    const isAccountLiteActive = useSelector(state => state.morpheus.isAccountLiteActive);
    const isAccountLiteActiveRef = useRef();
    isAccountLiteActiveRef.current = isAccountLiteActive;

    useEffect(() => {
        return () => {
            if (accountTimer) clearTimeout(accountTimer);
            if (promotionsTimer) clearTimeout(promotionsTimer);
        };
    }, [])

    const singleAccountUpdate = async (forceUpdate = false) => {
        if (!IS_LOGGED_IN || (isAccountLiteActiveRef.current && !forceUpdate)) return;
        const res = await execute();

        try {
            const {code, data, redirect} = res;
            if (redirect) {
                window.location = redirect;
                return;
            }

            if (code === 200) {
                const {claims, loyalty, limits, messages, wallets} = data;

                if (messages) {
                    const messagesCount = messages['x-total-count'];
                    const unreadMessagesCount = messages['x-total-unread-count'];

                    dispatch(updateMessagesCount(messagesCount, unreadMessagesCount));
                } else {
                    countMessages();
                }

                if (loyalty) {
                    dispatch(updatePlayerLoyaltyPoints(loyalty));
                }

                if (claims) {
                    dispatch(updatePlayerClaims(claims));
                }

                if (limits) {
                    dispatch(updatePlayerLimits(limits));
                }

                if (wallets) {
                    dispatch(updatePlayerWallets(wallets));
                }

                if (data?.quickBanking) {
                    dispatch(setHasQuickBanking(data.quickBanking));
                }
            }

            if (status !== "idle") {
                dispatch(updatePlayerClaimsStatus(status));
                // dispatch(updatePlayerLimitsStatus(status));
                dispatch(updatePlayerWalletsStatus(status));
            }


            return true;
        } catch (e) {
            //sendExceptionToSentry(e);
            console.log(e);
        }

        return false;
    };

    const refreshAccount = async () => {
        if (accountTimer) clearTimeout(accountTimer);
        await singleAccountUpdate();
        accountTimer = setTimeout(() => refreshAccount(), ACCOUNT_REFRESH_INTERVAL);
    };


    const singlePromotionsUpdate = async () => {
        getFreeSpins();
        listPromotions();
        listUsersActivePromotions({types: "CashbackOnNetLosses", statuses: "Active,PartQualified"});
    };

    const refreshPromotions = async () => {
        if (promotionsTimer) clearTimeout(promotionsTimer);
        await singlePromotionsUpdate();

        promotionsTimer = setTimeout(() => refreshPromotions(), PROMOTIONS_REFRESH_INTERVAL);
    };

    return {refreshAccount, refreshPromotions, singleAccountUpdate, singlePromotionsUpdate};
}

export function useAccountLite() {
    let timer = null;
    const dispatch = useDispatch();
    const litePath = 'authorizedPaths.myAccountObj.lite';
    const {execute, status, value, error} = useRedPill(litePath);

    useEffect(() => {
        dispatch(updateAccountLiteActive(true));

        return () => {
            singleAccountUpdate();
            dispatch(updateAccountLiteActive(false));
            clearTimeout(timer);
        };
    }, []);

    const singleAccountUpdate = async () => {
        if (!IS_LOGGED_IN) return;

        const res = await execute();

        try {
            const {code, data, redirect} = res;
            if (redirect) {
                window.location = redirect;
                return;
            }

            if (code === 200) {
                const {wallets} = data;
                if (wallets) {
                    dispatch(updatePlayerWallets(wallets));
                }
            }

            if (status !== "idle") {
                dispatch(updatePlayerWalletsStatus(status));
            }


            return true;
        } catch (e) {
            //sendExceptionToSentry(e);
            console.log(e);
        }

        return false;
    };

    const refreshAccount = async () => {
        if (timer) clearTimeout(timer);
        await singleAccountUpdate();
        timer = setTimeout(() => refreshAccount(), ACCOUNT_REFRESH_INTERVAL);

        return true;
    };

    return {refreshAccount, singleAccountUpdate};
}

const useGetLoyaltyPointsConfig = () => {
    const dispatch = useDispatch();
    const getLoyaltyPointsPath = 'authorizedPaths.myAccountObj.loyalty';
    const {execute, status, value, error} = useRedPill(getLoyaltyPointsPath);

    const getLoyaltyPointsConfig = async () => {
        const res = await execute();

        try {
            if (res.code === 200 && res.data) {
                dispatch(updateLoyaltyPointsConfig(res.data));
                return true;
            }
        } catch (e) {
            //sendExceptionToSentry(e);
            console.log(e);
            return false;
        }

        return false;
    };

    return {getLoyaltyPointsConfig, status};
};

const useRedeemLoyaltyPoints = () => {
    const {enqueueSnackbar} = useSnackbar();
    const getRedeemPointsPath = 'authorizedPaths.myAccountObj.loyalty';
    const {execute, status, value, error} = useRedPill(getRedeemPointsPath);

    const getRedeemPoints = async (data) => {
        const res = await execute({method: "POST", data});

        try {
            if (res.code === 200) {
                enqueueSnackbar(`Successfully redeemed ${data.redeemPoints} loyalty tokens!`, {variant: 'success'});
            } else {
                enqueueSnackbar(`Failed to redeem loyalty tokens`, {variant: 'error'});
            }
        } catch (e) {
            //sendExceptionToSentry(e);
            console.log(e);
        }

        return res;
    };

    return {getRedeemPoints, status};
};

export const useMyAccount = () => {
    const {loyaltyPoints, loyaltyPointsLoaded, loyaltyPointsConfig, loyaltyPointsConfigLoaded} = useSelector(state => ({
        loyaltyPoints: state.loyalty.pointsBalance,
        loyaltyPointsLoaded: state.loyalty.loyaltyLoaded,
        loyaltyPointsConfig: state.loyalty.config,
        loyaltyPointsConfigLoaded: state.loyalty.configLoaded
    }));

    return {
        _getMyAccount: useAccount,
        _getMyAccountLite: useAccountLite,
        _getLoyaltyPointsConfig: useGetLoyaltyPointsConfig,
        _redeemLoyaltyPoints: useRedeemLoyaltyPoints,
        loyaltyPoints,
        loyaltyPointsLoaded,
        loyaltyPointsConfig,
        loyaltyPointsConfigLoaded
    };
}
