import {useCallback, useEffect, useReducer, useRef} from "react";

const initialState = {status: 'idle', value: null, error: null};

function init() {
    return initialState;
}

function reducer(state, action) {
    switch (action.type) {
        case 'PENDING':
            return {...state, status: 'pending'};
        case 'SUCCESS':
            return {...state, status: 'success', value: action.response};
        case 'ERROR':
            return {...state, status: 'error', error: action.error};
        default:
            throw new Error();
    }
}

export const useAsync = (asyncFunction) => {
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const isAlive = useRef(true);

    useEffect(() => {
        return () => isAlive.current = false;
    }, []);

    const execute = useCallback((params) => {
        if (state.status === "pending") return;

        if (isAlive.current) {
            dispatch({type: 'PENDING'});
        }

        return asyncFunction(params)
            .then((response) => {
                if (response.code === 200) {
                    if (isAlive.current) {
                        dispatch({type: 'SUCCESS', response});
                    }
                    return response;
                } else {
                    if (isAlive.current) {
                        dispatch({type: 'ERROR', response});
                    }
                    return response;
                }

                // if (isAlive.current) {
                //     dispatch({type: 'ERROR', error});
                // }
            })
            .catch((error) => {
                //sendExceptionToSentry(error);

                if (isAlive.current) {
                    dispatch({type: 'ERROR', error});
                }

                return false;
            });
    }, [asyncFunction]);

    return {execute, ...state};
};
