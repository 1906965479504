import React, {useEffect, useRef, useState} from "react";
import RouteLoadingSplash from "Components/Layouts/RouteLoadingSplashScreen";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {LoggedInRoute, LoggedOutRoute} from "Helpers/privateRoute";
import {
    Lazy404,
    Lazy500,
    LazyAccountPendingAIDPage,
    LazyAgeAndIdentityCheck,
    LazyBingo,
    LazyChangePasswordPage,
    LazyContactCSPage,
    LazyDepositPage,
    LazyDesktopBingoGamePage,
    LazyDesktopGamePage,
    LazyDragonfishOperatedSites,
    LazyHomePage,
    LazyIdentityCheckInfoPage,
    LazyLobby,
    LazyLoginPage,
    LazyMaintenancePage,
    LazyMobileBingoGamePage,
    LazyMobileGamePage,
    LazyNewPlayerHomepage,
    LazyPayoutRates,
    LazyPrimaryLayout,
    LazyPromos,
    LazyRegistration,
    LazyRegistrationLayout,
    LazyRequestPasswordPage,
    LazyResponsibleGamblingApp,
    LazyResponsibleGamblingPage,
    LazyRewards,
    LazySaferGamblingWeekPage,
    LazyServerProvidedContentPage,
    LazySlots,
    LazySourceOfFunds,
    LazySpecialEventPromo,
    LazySupportPage,
    LazyTermsAndConditions,
    LazyTermsAndConditionsChangelog,
    LazyVerificationMessageReceiver,
} from "./lazyRoutes";

import {
    ABOUT_US_URL,
    BINGO_RULES_URL,
    BINGO_URL,
    BONUS_MONEY_RULES_URL,
    BROADWAY_GAMING_GROUP_SITES_URL,
    COOKIE_POLICY_URL,
    FB_TERMS_URL,
    HOME_URL,
    LOGIN_URL,
    NEW_PLAYER_HOMEPAGE_URL,
    PAYOUT_RATES_URL,
    PLAYER_PROTECTION_URL,
    PRIVACY_POLICY_URL,
    PROMOS_URL,
    PROMOTIONAL_TERMS_URL,
    PROTECTION_OF_FUNDS_URL,
    REGISTER_URL,
    RESPONSIBLE_GAMBLING_INFO_URL,
    RESPONSIBLE_GAMBLING_URL,
    REWARDS_URL,
    SAFER_GAMBLING_URL,
    SECURITY_URL,
    SLOTS_URL,
    SOURCE_OF_FUNDS_URL,
    SUPPORT_URL,
    TERMS_AND_CONDITIONS_URL
} from "Setup/urls";

import {
    CAN_LOGIN,
    CAN_REGISTER,
    FB_TERMS_ENABLED,
    IS_BRAND_IN_MAINTENANCE_MODE,
    IS_CLIENT_FROM_GEO_BLOCKED_COUNTRY,
    IS_HOT_RELOAD_ENABLED,
    IS_LOGGED_IN,
    IS_MOBILE,
    IS_PROD,
    IS_THEME_SWITCHER_ENABLED,
    LOAD_ACCOUNT_VERIFICATION_PAGES_FROM_FORGE,
    NEW_PLAYER_HOMEPAGE_ENABLED,
    PROMOS_ENABLED,
    RESTRICTED_COUNTRY_ROUTE,
    REWARDS_ENABLED,
    SPECIAL_EVENT_PROMO_ENABLED,
    SPECIAL_EVENT_PROMO_URL,
    VERIFICATION_MESSAGE_MODAL_ENABLED
} from "Setup/config";

import UpdatesPage from "Pages/Updates";
import ErrorBoundary from "Components/ErrorBoundary";
import ModalRoot from "Components/Modal/ModalRoot";
import {useZendesk} from "Hooks/useZendesk";
import useIdleUntilUrgent from "use-idle-until-urgent";
import {sendGA4Event} from "Helpers/gaEvents";
import ThemeSwitcher from "Components/DevTools/ThemeSwitcher";
import {sanitizeUrl} from "Helpers";
import GeoBlockedPage from "Pages/GeoBlockedPage";
import {sendSnapchatPageview} from "Helpers/snapchat";

import BankingEventListener from "Components/BankingEventListener";

function usePageViews() {
    const location = useLocation();
    const firstUpdate = useRef(true);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        } else {
            // Scroll to the top of the page on route change
            if (!IS_HOT_RELOAD_ENABLED) {
                window.scrollTo(0, 0);
            }
        }

        const sanitizedSearch = sanitizeUrl(location.search);
        if (!IS_PROD) console.log(`${location.pathname}${sanitizedSearch}`);
        sendGA4Event('page_view', {page_location: `${location.pathname}${sanitizedSearch}`});

        sendSnapchatPageview();

    }, [location]);
}

const LocationWrapper = ({children}) => {
    usePageViews();

    return children;
};

const LiveAgent = () => {
    let chatInitDelay = IS_LOGGED_IN ? 2000 : IS_MOBILE ? 5000 : 3000;

    const {initZendesk} = useZendesk();
    const IdleUntilUrgentlyLoadedComponent = useIdleUntilUrgent(
        async () => {
            setTimeout(() => {
                initZendesk();
            }, chatInitDelay);

            return () => null;
        },
        {
            fallback: () => null
        }
    );

    return <IdleUntilUrgentlyLoadedComponent/>;
};

const Routes = () => {
    const location = useLocation();
    const [zendeskInitiated, setZendeskInitiated] = useState(false);

    useEffect(() => {
        if (!location.pathname.includes("/offer") && !location.pathname.includes("/welcome-offer") && !(NEW_PLAYER_HOMEPAGE_ENABLED && location.pathname === "/")) {
            setZendeskInitiated(true);
        }
    }, [location.pathname]);

    if (NEW_PLAYER_HOMEPAGE_ENABLED && location.pathname === "/") {
        return <Redirect to={NEW_PLAYER_HOMEPAGE_URL}/>;
    }

    return (
        <ErrorBoundary>
            <LocationWrapper>
                <React.Suspense fallback={<RouteLoadingSplash/>}>
                    {VERIFICATION_MESSAGE_MODAL_ENABLED &&
                        <ErrorBoundary>
                            <LoggedInRoute component={LazyVerificationMessageReceiver}/>
                        </ErrorBoundary>
                    }
                    <Switch>
                        <Route path={RESTRICTED_COUNTRY_ROUTE} component={GeoBlockedPage}/>
                        {IS_CLIENT_FROM_GEO_BLOCKED_COUNTRY &&
                            <Route> <Redirect to={RESTRICTED_COUNTRY_ROUTE}/> </Route>
                        }

                        {IS_BRAND_IN_MAINTENANCE_MODE ?
                            <>
                                <Route path="/maintenance" component={LazyMaintenancePage}/>
                                <Route> <Redirect to="/maintenance"/> </Route>
                            </> :
                            <Route path="/maintenance"> <Redirect to={HOME_URL}/> </Route>
                        }

                        {(NEW_PLAYER_HOMEPAGE_ENABLED) ?
                            <LoggedOutRoute path={NEW_PLAYER_HOMEPAGE_URL} component={LazyNewPlayerHomepage}/> :
                            <Route path={NEW_PLAYER_HOMEPAGE_URL}> <Redirect to={HOME_URL}/> </Route>
                        }

                        <Route path="/lost-campaign"> <Redirect to={HOME_URL}/> </Route>

                        {CAN_REGISTER ?
                            <Route path={REGISTER_URL}>
                                <LazyRegistrationLayout>
                                    <LoggedOutRoute path="/" component={LazyRegistration}/>
                                </LazyRegistrationLayout>
                            </Route> :
                            <Route path={REGISTER_URL}> <Redirect to={HOME_URL}/> </Route>
                        }

                        {CAN_LOGIN ?
                            <LoggedOutRoute path={[LOGIN_URL, '/welcome/', '/lost-password/:playerId']} component={LazyLoginPage}/> :
                            <Route path={LOGIN_URL}> <Redirect to={HOME_URL}/> </Route>
                        }

                        <Route path="/game">
                            <Switch>
                                <LoggedInRoute path="/game/desktop-casino-game/:launchId" component={LazyDesktopGamePage}/>
                                <LoggedInRoute path="/game/mobile-casino-game/:launchId" component={LazyMobileGamePage}/>

                                <LoggedInRoute path="/game/desktop-bingo-game/:launchId" component={LazyDesktopBingoGamePage}/>
                                <LoggedInRoute path="/game/mobile-bingo-game/:launchId" component={LazyMobileBingoGamePage}/>
                            </Switch>
                        </Route>

                        <Route path={RESPONSIBLE_GAMBLING_INFO_URL}>
                            <Switch>
                                <Route path={RESPONSIBLE_GAMBLING_INFO_URL} component={LazyResponsibleGamblingPage}/>
                            </Switch>
                        </Route>

                        <Route path={SAFER_GAMBLING_URL}>
                            <Switch>
                                <Route path={SAFER_GAMBLING_URL} component={LazySaferGamblingWeekPage}/>
                            </Switch>
                        </Route>

                        <LoggedInRoute path={RESPONSIBLE_GAMBLING_URL} component={LazyResponsibleGamblingApp}/>
                        <LoggedInRoute path="/deposit" component={LazyDepositPage}/>

                        <Route>
                            <LazyPrimaryLayout>
                                <Switch>
                                    <LoggedOutRoute path="/" exact component={LazyHomePage}/>
                                    <LoggedInRoute path="/lobby" exact component={LazyLobby}/>

                                    {PROMOS_ENABLED ? <Route path={PROMOS_URL} component={LazyPromos}/> :
                                        <Route path={PROMOS_URL}>
                                            <Redirect to={HOME_URL}/>
                                        </Route>
                                    }

                                    {REWARDS_ENABLED && <Route path={REWARDS_URL} component={LazyRewards}/>}
                                    <Route path={SLOTS_URL} component={LazySlots}/>
                                    <Route path={BINGO_URL} component={LazyBingo}/>

                                    {SPECIAL_EVENT_PROMO_ENABLED ?
                                        <LoggedInRoute path={SPECIAL_EVENT_PROMO_URL} component={LazySpecialEventPromo}/> :
                                        <Route path={SPECIAL_EVENT_PROMO_URL}>
                                            <Redirect to={HOME_URL}/>
                                        </Route>
                                    }

                                    <Route path="/updates/contact-cs" component={LazyContactCSPage}/>
                                    <Route path="/updates/account-pending-aid" component={LazyAccountPendingAIDPage}/>

                                    {/* TODO: Temporary routes for updates page issue */}
                                    <Route path="/updates/account-self-excluded/:playerId"
                                           render={props => <LazyServerProvidedContentPage {...props} pathOverride="/updates/account-self-excluded"/>}/>
                                    <Route path="/updates/account-taking-a-break/:playerId"
                                           render={props => <LazyServerProvidedContentPage {...props} pathOverride="/updates/account-taking-a-break"/>}/>
                                    <Route path="/updates/account-banned/:playerId"
                                           render={props => <LazyServerProvidedContentPage {...props} pathOverride="/updates/account-banned"/>}/>

                                    <Route path="/updates/:page" component={UpdatesPage}/>
                                    <LoggedOutRoute path="/recover-my-password" component={LazyChangePasswordPage}/>
                                    <LoggedOutRoute path={["/lost-details", "/support/forgot-your-password"]} component={LazyRequestPasswordPage}/>

                                    {/* <------ FOOTER LINKS ------> */}
                                    <Route path={ABOUT_US_URL} exact component={LazyServerProvidedContentPage}/>
                                    <Route path={PLAYER_PROTECTION_URL} exact component={LazyServerProvidedContentPage}/>
                                    <Route path={SECURITY_URL} exact component={LazyServerProvidedContentPage}/>
                                    <Route path="/account-verification">
                                        <Switch>
                                            <Route path="/account-verification/age-and-identity-check"
                                                   component={LOAD_ACCOUNT_VERIFICATION_PAGES_FROM_FORGE ? LazyServerProvidedContentPage : LazyAgeAndIdentityCheck}/>
                                            <Route path="/account-verification/identity-check"
                                                   component={LOAD_ACCOUNT_VERIFICATION_PAGES_FROM_FORGE ? LazyServerProvidedContentPage : LazyAgeAndIdentityCheck}/>
                                            <Route path="/account-verification/source-of-funds"
                                                   component={LOAD_ACCOUNT_VERIFICATION_PAGES_FROM_FORGE ? LazyServerProvidedContentPage : LazyAgeAndIdentityCheck}/>
                                            <Route path="/" component={LOAD_ACCOUNT_VERIFICATION_PAGES_FROM_FORGE ? LazyServerProvidedContentPage : LazyAgeAndIdentityCheck}/>
                                        </Switch>
                                    </Route>
                                    <Route path={"/support/identity-check"} component={LazyIdentityCheckInfoPage}/>
                                    <Route path={SOURCE_OF_FUNDS_URL} component={LazySourceOfFunds}/>
                                    <Route exact path={BINGO_RULES_URL} render={props => <LazyTermsAndConditions {...props} path="bingo-rules" title="Bingo Rules"/>}/>
                                    <Route exact path={TERMS_AND_CONDITIONS_URL}
                                           render={props => <LazyTermsAndConditions {...props} path="terms-and-conditions" title="Terms & Conditions"/>}/>
                                    <Route path={`${TERMS_AND_CONDITIONS_URL}/changelog`} component={LazyTermsAndConditionsChangelog}/>
                                    <Route path={[PROMOTIONAL_TERMS_URL, BONUS_MONEY_RULES_URL]}
                                           render={props => <LazyTermsAndConditions {...props} path="promotional-terms" title="Promotional Terms"/>}/>
                                    {FB_TERMS_ENABLED &&
                                        <Route path={FB_TERMS_URL} render={
                                            props => <LazyTermsAndConditions {...props} path="fb-competitions-terms-and-conditions" title="Facebook Competitions Terms"/>
                                        }/>
                                    }

                                    <Route path={PRIVACY_POLICY_URL} render={props => <LazyTermsAndConditions {...props} path="privacy-policy" title="Privacy Policy"/>}/>
                                    <Route path={COOKIE_POLICY_URL} render={props => <LazyTermsAndConditions {...props} path="cookie-policy" title="Cookie Policy"/>}/>
                                    <Route path={PROTECTION_OF_FUNDS_URL}
                                           render={props => <LazyTermsAndConditions {...props} path="protection-of-funds" title="Protection of Funds"/>}/>

                                    <Route path={PAYOUT_RATES_URL} component={LazyPayoutRates}/>
                                    <Route path={SOURCE_OF_FUNDS_URL} render={(props) => (
                                        <LazyServerProvidedContentPage {...props} pageContentRoute="protection-of-funds"/>
                                    )}/>

                                    <Route path={BROADWAY_GAMING_GROUP_SITES_URL} component={LazyDragonfishOperatedSites}/>

                                    {/* <------ FOOTER LINKS ------> */}

                                    <Route path="/api/game-history"> <Redirect to="/lobby?showGameHistory=1"/> </Route>
                                    <Route path="/cashcheck"> <Redirect to="/lobby?showTransactionHistory=1"/> </Route>

                                    <Route path={SUPPORT_URL} component={LazySupportPage}/>
                                    <Route path="/page-not-found" component={Lazy404}/>
                                    <Route path="/500" component={Lazy500}/>
                                    <Route path="/internal-server-error" component={Lazy500}/>
                                    <Route component={Lazy404}/>
                                </Switch>
                            </LazyPrimaryLayout>
                        </Route>
                    </Switch>
                </React.Suspense>
            </LocationWrapper>
            <ErrorBoundary doNotRenderModalsWhenError>
                <ModalRoot/>
            </ErrorBoundary>

            {IS_THEME_SWITCHER_ENABLED && <ThemeSwitcher/>}
            {zendeskInitiated && <LiveAgent/>}
            {IS_LOGGED_IN && <BankingEventListener/>}
        </ErrorBoundary>
    );
}

export default Routes;
