import {UPDATE_PLAYER_LIMIT, UPDATE_PLAYER_LIMITS, UPDATE_SESSION_REMINDER} from "Redux/actions/limits";

const limitsReducer = (state = {isLoaded: false}, action) => {
    switch (action.type) {
        case UPDATE_PLAYER_LIMITS:
            return {...state, isLoaded: true, ...action.newPlayerLimits};
        case UPDATE_PLAYER_LIMIT:
            if (state.isLoaded) {
                return Object.assign({}, state, {
                    depositLimitsConfirmed: true,
                    [action.interval]: action.setting
                });
            }

            return state;
        case UPDATE_SESSION_REMINDER:
            if (state.isLoaded) {
                return Object.assign({}, state, {
                    sessionReminder: {
                        ...state.sessionReminder,
                        activeValue: action.interval
                    }
                });
            }

            return state;
        default:
            return {...state};
    }
};

export default limitsReducer;
