import {IS_LOGGED_IN, IS_SNAPCHAT_TRACKING_ENABLED, SNAPCHAT_TRACKING_PIXEL_ID} from "../setup/config";
import {sendLogglyEvent} from "./loggly";
import getConsentValueById from "Helpers/getConsentValueById";

const SNAPCHAT_PIXEL_ID = SNAPCHAT_TRACKING_PIXEL_ID;
const SNAPCHAT_TRACKING_ENABLED = IS_SNAPCHAT_TRACKING_ENABLED && SNAPCHAT_TRACKING_PIXEL_ID;

const hasUserGivenConsent = () => {
    return getConsentValueById('ad_user_data');
};

export function addSnapchatPixelScript(){
    if(!SNAPCHAT_TRACKING_ENABLED) return false;
    if(!hasUserGivenConsent()) return false;

    (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
    {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
        a.queue=[];var s='script';var r=t.createElement(s);r.async=!0;
        r.src=n;var u=t.getElementsByTagName(s)[0];
        u.parentNode.insertBefore(r,u);})(window,document,
        'https://sc-static.net/scevent.min.js');
}
export function initialiseSnapchatTracking() {
    if (!SNAPCHAT_TRACKING_ENABLED) return false;
    if (!hasUserGivenConsent()) return false;

    addSnapchatPixelScript();

    let payload = {};
    try {
        if (IS_LOGGED_IN) {
            const MORPHEUS = window.MORPHEUS;
            if (MORPHEUS?.personal?.email) {
                payload.user_email = MORPHEUS.personal.email;
            }

            if (MORPHEUS?.personal?.mobile) {
                payload.user_phone_number = MORPHEUS.personal.mobile;
            }
        }
    } catch (e) {
        sendLogglyEvent('Snapchat pixel', 'Init fail', {error: e})
    }

    window.snaptr('init', SNAPCHAT_PIXEL_ID, payload);
}

export function sendSnapchatPageview() {
    if (!SNAPCHAT_TRACKING_ENABLED || !IS_LOGGED_IN) return false;
    if (!hasUserGivenConsent()) return false;

    window.snaptr('track', 'PAGE_VIEW');
}

export function sendSnapchatFTD({value, currency, transaction_id}) {
    if (!SNAPCHAT_TRACKING_ENABLED) return false;
    if (!hasUserGivenConsent()) return false;

    window.snaptr('track', 'PURCHASE', {
        price: value,
        currency,
        transaction_id
    });
}

export function sendSnapchatRegistration({email}) {
    if (!SNAPCHAT_TRACKING_ENABLED) return false;
    if (!hasUserGivenConsent()) return false;

    window.snaptr('init', SNAPCHAT_PIXEL_ID, {
        user_email: email
    });

    window.snaptr('track', 'SIGN_UP');
}