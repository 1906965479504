import {FREE_SPINS_REMAINING} from "Redux/actions/freeSpins";

let initialState = {isLoaded: false, games: []};

const freeSpinsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FREE_SPINS_REMAINING:
            return {
                ...state,
                isLoaded: true,
                games: action.freeSpinsGames,
            };
        default:
            return {
                ...state
            };
    }
};

export default freeSpinsReducer;
