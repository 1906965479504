const scriptSettingsDefault = {
    callbackOnSuccess: () => {
    },
    callbackOnError: () => {
    },
    id: '',
    defer: true
};

const loadExternalScript = (src, settings) => {
    if (!src) {
        console.log('Script path invalid.');
        return false;
    }

    const scriptSettings = {...scriptSettingsDefault, ...settings};
    console.log('script settings::');
    console.log(scriptSettings);

    let script = document.createElement('script');
    script.src = src;
    script.defer = scriptSettings.defer;
    script.type = 'text/javascript';
    script.async = true;
    if (scriptSettings.id && typeof scriptSettings.id === 'string') {
        script.id = scriptSettings.id;
    }

    script.onerror = (err) => {
        console.log('Error loading script:');
        console.log(err);
        scriptSettings.callbackOnError();
    }

    script.onload = scriptSettings.callbackOnSuccess;

    document.head.appendChild(script);
};

export default loadExternalScript;
