import {combineReducers} from "redux";
import morpheusReducer from "./morpheusReducer";
import walletsReducer from "./walletsReducer";
import limitsReducer from "./limitsReducer";
import claimsReducer from "./claimsReducer";
import uiReducer from "./uiReducer";
import lobbyReducer from "./lobbyReducer";
import promotionsReducer from "./promotionsReducer";
import responsibleGamblingReducer from "./responsibleGamblingReducer";
import messagesReducer from "./messagesReducer";
import liveAgentReducer from "./liveAgentReducer";
import loyaltyReducer from "./loyaltyReducer";
import bingoReducer from "./bingoReducer";
import freeSpinsReducer from "Redux/reducers/freeSpinsReducer";

const rootReducer = combineReducers({
    bingo: bingoReducer,
    claims: claimsReducer,
    limits: limitsReducer,
    lobby: lobbyReducer,
    messages: messagesReducer,
    morpheus: morpheusReducer,
    promotions: promotionsReducer,
    responsibleGambling: responsibleGamblingReducer,
    ui: uiReducer,
    wallets: walletsReducer,
    liveAgent: liveAgentReducer,
    loyalty: loyaltyReducer,
    freeSpins: freeSpinsReducer
});

export default rootReducer;
