// Action Types
export const UPDATE_PLAYER_WALLETS = "UPDATE_PLAYER_WALLETS";
export const UPDATE_PLAYER_WALLETS_STATUS = "UPDATE_PLAYER_WALLETS_STATUS";

// Action Creator
export const updatePlayerWallets = (newPlayerWallets) => ({
    type: UPDATE_PLAYER_WALLETS,
    newPlayerWallets,
});

export const updatePlayerWalletsStatus = (status) => ({
    type: UPDATE_PLAYER_WALLETS_STATUS,
    status,
});
