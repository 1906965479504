import useStorage from "Hooks/useStorage";
import {useRedPill} from "Hooks/api/useRedPill";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {updateRecentGames} from "Redux/actions/lobby";

export function useGetRecentGames() {
    const dispatch = useDispatch();
    const {setValue} = useStorage("recentGames", null, "localStorage");
    const {execute, status, value, error} = useRedPill("authorizedPaths.recentGames");

    const getRecentGames = async () => {
        if (status === "pending") return;

        const res = await execute();

        if (res.code === 200) {
            let games = res.data.map(game => game.catalogueGameId);
            setValue(games);
            dispatch(updateRecentGames(games));

            return true;
        }

        return false;
    }

    return {getRecentGames, status, value, error};
}

export const useRecentGames = () => {
    const {isRecentGamesLoaded, isLoaded, recentGames, gamesData} = useSelector(state => {
        const {isRecentGamesLoaded, isLoaded, recentGames, games: gamesData} = state.lobby;
        return {isRecentGamesLoaded, isLoaded, recentGames, gamesData};
    }, shallowEqual);

    const [filteredGamed, setFilteredGames] = React.useState([]);

    React.useEffect(() => {
        try {
            if (isLoaded && gamesData) {
                // Temp remove fluffy favourites from recently played games list
                const games = recentGames.filter(g => gamesData.hasOwnProperty(g));
                setFilteredGames(games);
            }
        } catch (e) {
            console.log(e);
        }
    }, [recentGames, gamesData, isLoaded]);

    return {
        recentGames: filteredGamed,
        isRecentGamesReady: isRecentGamesLoaded && isLoaded,
        _get: useGetRecentGames,
    };
};
