import ModalBase from "../index";
import {DialogContent} from "@material-ui/core";
import React from "react";

const StandaloneModal = ({title, children, ...rest}) => {
    return (
        <ModalBase title={title} handleClose={rest.onClose} {...rest}>
            <DialogContent>
                {children}
            </DialogContent>
        </ModalBase>
    );
};

export default StandaloneModal;
