export const BP_SMALL = 0;
export const BP_MEDIUM = 640;
export const BP_LARGE = 1024;
export const BP_XLARGE = 1280;
export const BP_XXLARGE = 1600;

export const breakpoints = {
    keys: ["xs", "sm", "md", "lg", "xl", "xxl"],
    values: {
        small: 0,
        medium: 640,
        large: 1024,
        xlarge: 1280,
        xxlarge: 1600,
    },
};
