import {useRedPill} from "Hooks/api/useRedPill";
import {useEffect} from "react";

const PING_INTERVAL = 1000 * 60 * 10;
const INITIAL_WAIT_PERIOD = 1000 * 15;

export function usePing() {
    let timer = null;
    const {execute} = useRedPill('ping');

    useEffect(() => {
        setTimeout(() => ping(), INITIAL_WAIT_PERIOD);

        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [])

    const ping = async () => {
        if (timer) clearTimeout(timer);
        await execute();
        timer = setTimeout(() => ping(), PING_INTERVAL);
    }
}
