import {UPDATE_BINGO_FEED, UPDATE_DF_BINGO_FEED} from "Redux/actions/bingo";

let initialState = {isLoaded: false, rooms: [], specials: [], dfRooms: {isLoaded: false, rooms: []}};

const bingoReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_BINGO_FEED:
            return {
                ...state,
                isLoaded: true,
                rooms: action.newRooms,
                specials: action.newSpecials,
                dictionary: action.roomDictionary
            };
        case UPDATE_DF_BINGO_FEED:
            return {
                ...state,
                dfRooms:{
                    isLoaded: true,
                    rooms: action.dfRooms
                }
            };
        default:
            return {
                ...state
            };
    }
};

export default bingoReducer;
