import {useLocation} from "react-router-dom";
import {Lazy404, LazyServerProvidedContentPage} from "Containers/lazyRoutes";

const UpdatesPage = () => {
    const location = useLocation();

    const updatesRoutesObj = window.MORPHEUS.routes.updates;
    const pageExists = Object.values(updatesRoutesObj).find(page => {
        return page === location.pathname;
    })

    console.log(window.PAGE.id, location.pathname);

    if (pageExists || (window.hasOwnProperty('PAGE') && location.pathname.includes(window.PAGE.id))) {
        return <LazyServerProvidedContentPage/>
    }

    return <Lazy404/>
};

export default UpdatesPage;
