import {useSelector} from "react-redux";
import React from "react";
import ErrorBoundary from "Components/ErrorBoundary";
import StandaloneModal from "Components/Modal/Modals/standaloneModal";
import {ErrorRetry} from "Containers/lazyRoutes";
import {Box} from "@material-ui/core";

const RetryModal = (props) => (
    <StandaloneModal title={'\u00A0'}>
        <Box display="flex" flexDirection="column">
            <ErrorRetry retry={props.retry}/>
        </Box>
    </StandaloneModal>
)

export const LazyLoaderModalFactory = (promise, Loader = () => null, ErrorRetryView = ({retry}) => <RetryModal retry={retry}/>) => {
    function LazyLoader(props) {
        const [loading, setLoading] = React.useState(true);
        const retry = React.useCallback(() => setLoading(true), []);

        const Lazy = React.useMemo(() => React.lazy(() => promise().catch(() => {
            setLoading(false);
            return {default: () => <ErrorRetryView retry={retry}/>};
        })), [promise, loading]);

        return <React.Suspense fallback={<Loader/>}><Lazy {...props}/></React.Suspense>;
    }

    return LazyLoader;
};

const LazyModal = ({component: Component, ...rest}) => <ErrorBoundary doNotRenderModalsWhenError><Component {...rest} /></ErrorBoundary>;

// Deferred Modals, only required when logged in
const AcceptTerms = LazyLoaderModalFactory(() => import(/* webpackChunkName: "Modals" */ './Modals/acceptTerms'));
export const AcceptTermsModal = props => <LazyModal component={AcceptTerms} {...props} />;

const Banking = LazyLoaderModalFactory(() => import(/* webpackChunkName: "Modals" */ './Modals/bankingFull'));
export const BankingModal = props => <LazyModal component={Banking} {...props} />;

const BonusHistory = LazyLoaderModalFactory(() => import(/* webpackChunkName: "Modals" */ './Modals/bonusHistory'));
export const BonusHistoryModal = props => <LazyModal component={BonusHistory} {...props} />;

const TransactionHistory = LazyLoaderModalFactory(() => import(/* webpackChunkName: "Modals" */ './Modals/transactionHistory'));
export const TransactionHistoryModal = props => <LazyModal component={TransactionHistory} {...props} />;

const PlayHistory = LazyLoaderModalFactory(() => import(/* webpackChunkName: "Modals" */ './Modals/playHistory'));
export const PlayHistoryModal = props => <LazyModal component={PlayHistory} {...props} />;

const Withdrawal = LazyLoaderModalFactory(() => import(/* webpackChunkName: "Modals" */ './Modals/withdrawal'));
export const WithdrawalModal = props => <LazyModal component={Withdrawal} {...props} />;

const DailyDeal = LazyLoaderModalFactory(() => import(/* webpackChunkName: "Modals" */ 'Components/SlideDownGame/DailyDeal'));
export const DailyDealModal = props => <LazyModal component={DailyDeal} {...props} />;

const FreeSpinsGame = LazyLoaderModalFactory(() => import(/* webpackChunkName: "Modals" */ 'Components/SlideDownGame/FreeSpinsGame'));
export const FreeSpinsGameModal = props => <LazyModal component={FreeSpinsGame} {...props} />;

const LoyaltyTokens = LazyLoaderModalFactory(() => import(/* webpackChunkName: "Modals" */ './Modals/loyaltyTokens'));
export const LoyaltyTokensModal = props => <LazyModal component={LoyaltyTokens} {...props} />;

const PlayingWithBonusFunds = LazyLoaderModalFactory(() => import(/* webpackChunkName: "Modals" */ './Modals/playingWithBonusFunds'));
export const PlayingWithBonusFundsModal = props => <LazyModal component={PlayingWithBonusFunds} {...props} />;

const ChangePassword = LazyLoaderModalFactory(() => import(/* webpackChunkName: "Modals" */ './Modals/changePassword'));
export const ChangePasswordModal = props => <LazyModal component={ChangePassword} {...props} />;

const Search = LazyLoaderModalFactory(() => import(/* webpackChunkName: "Modals" */ './Modals/search'));
export const SearchModal = props => <LazyModal component={Search} {...props} />;

const PromotionalTerms = LazyLoaderModalFactory(() => import(/* webpackChunkName: "LoggedOutModals" */ './Modals/promotionalTerms'));
export const PromotionalTermsModal = props => <LazyModal component={PromotionalTerms} {...props} />;

const Logout = LazyLoaderModalFactory(() => import(/* webpackChunkName: "Modals" */ './Modals/logout'));
export const LogoutModal = props => <LazyModal component={Logout} {...props} />;

const Loading = LazyLoaderModalFactory(() => import(/* webpackChunkName: "Modals" */ './Modals/loadingModal'));
export const LoadingModal = props => <LazyModal component={Loading} {...props} />;

const Promotion = LazyLoaderModalFactory(() => import(/* webpackChunkName: "Modals" */ './Modals/promotion'));
export const PromotionModal = props => <LazyModal component={Promotion}  {...props} />;

const BingoHistory = LazyLoaderModalFactory(() => import(/* webpackChunkName: "Modals" */ './Modals/bingoHistory'));
export const BingoHistoryModal = props => <LazyModal component={BingoHistory} {...props} />;

const PostFreeSpinsClaimGameLaunch = LazyLoaderModalFactory(() => import(/* webpackChunkName: "Modals" */ './Modals/postFreeSpinsClaimGameLaunch'));
export const PostFreeSpinsClaimGameLaunchModal = props => <LazyModal component={PostFreeSpinsClaimGameLaunch} {...props} />;

const LoginLobby = LazyLoaderModalFactory(() => import(/* webpackChunkName: "Modals" */ './Modals/loginLobby'));
export const LoginLobbyModal = props => <LazyModal component={LoginLobby} {...props} />;

const PickYourBonus = LazyLoaderModalFactory(() => import(/* webpackChunkName: "Modals" */ 'Components/SlideDownGame/PickYourBonus'));
export const PickYourBonusModal = props => <LazyModal component={PickYourBonus} {...props} />;

const SpinTheWheel = LazyLoaderModalFactory(() => import(/* webpackChunkName: "SpinTheWheelModal" */ 'Components/SlideDownGame/SpinTheWheel'));
export const SpinTheWheelModal = props => <LazyModal component={SpinTheWheel} {...props} />;

const RiskTrigger = LazyLoaderModalFactory(() => import(/* webpackChunkName: "Modals" */ './Modals/riskTrigger'));
export const RiskTriggerModal = props => <LazyModal component={RiskTrigger} {...props} />;

const ServiceAnnouncement = LazyLoaderModalFactory(() => import(/* webpackChunkName: "Modals" */ './Modals/serviceAnnouncementModal'));
export const ServiceAnnouncementModal = props => <LazyModal component={ServiceAnnouncement} {...props} />;

const ReturningLoginLightbox = LazyLoaderModalFactory(() => import(/* webpackChunkName: "Modals" */ './Modals/returningLoginLightbox'));
export const ReturningLoginLightboxModal = props => <LazyModal component={ReturningLoginLightbox} {...props} />;

const MODALS = {
    "AcceptTerms": AcceptTermsModal,
    // "AgeAndIdentity": AgeAndIdentityModal,
    "Banking": BankingModal,
    "BingoHistory": BingoHistoryModal,
    "BonusHistory": BonusHistoryModal,
    "ChangePassword": ChangePasswordModal,
    "DailyDeal": DailyDealModal,
    "FreeSpinsGame": FreeSpinsGameModal,
    "Loading": LoadingModal,
    "Logout": LogoutModal,
    // "Login": LoginModal,
    "LoyaltyTokens": LoyaltyTokensModal,
    "PickYourBonus": PickYourBonusModal,
    "PlayHistory": PlayHistoryModal,
    "PlayingWithBonusFunds": PlayingWithBonusFundsModal,
    "PostFreeSpinsClaimGameLaunch": PostFreeSpinsClaimGameLaunchModal,
    "Promotion": PromotionModal,
    // "PreDepositPromotion": PreDepositPromotionModal,
    "PromotionalTerms": PromotionalTermsModal,
    "Search": SearchModal,
    "TransactionHistory": TransactionHistoryModal,
    "Withdrawal": WithdrawalModal,
    "WelcomeLightbox": LoginLobbyModal,
    "RiskTrigger": RiskTriggerModal,
    "SpinTheWheel": SpinTheWheelModal,
    "ServiceAnnouncement": ServiceAnnouncementModal,
    "ReturningLoginLightbox": ReturningLoginLightboxModal,
};

export default function (props) {
    const {modalQueue, pendingQueue} = useSelector(state => {
        const pendingQueue = state.ui.modals.queue.filter(m => m?.modalProps?.isPending && m.modalProps.isPending);
        const modalQueue = state.ui.modals.queue.filter(m => !m?.modalProps?.isPending || !m.modalProps.isPending);

        return {
            pendingQueue,
            modalQueue
        }
    });

    if (props.hasError) return null;

    return (
        <>
            {modalQueue.length === 0 && pendingQueue.map(modal => {
                const ModalComponent = MODALS[modal.modalId];
                return (
                    <ErrorBoundary key={modal.modalId}>
                        <ModalComponent modalId={modal.modalId} {...modal.modalProps}/>
                    </ErrorBoundary>
                );
            })}
            {modalQueue.map(modal => {
                const ModalComponent = MODALS[modal.modalId];
                return (
                    <ErrorBoundary key={modal.modalId}>
                        <ModalComponent modalId={modal.modalId} {...modal.modalProps}/>
                    </ErrorBoundary>
                );
            })}
        </>
    );
}
