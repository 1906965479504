import React, {useEffect} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import {CssBaseline, MuiThemeProvider, Typography} from "@material-ui/core";
// import useStorage from "Hooks/useStorage";
import {usePing} from "Hooks/api/usePing";
import {SnackbarProvider} from "notistack";
import useLobby from "Hooks/useLobby";
import {useMyAccount} from "Hooks/api/useAccount";
import {useGomTheme} from "Hooks/ui/useGomTheme";
import Routes from "./routes";
import {IS_LOGGED_IN} from "Setup/config";
import supportsWebP from "supports-webp";
import {LazyBedeNotifications} from "Components/LazyLoadedComponents/LazyBedeNotifications";

const App = (props) => {
    usePing();
    const {appliedTheme, themeReady} = useGomTheme();

    const {_getMyAccount} = useMyAccount();
    const {refreshAccount, refreshPromotions} = _getMyAccount();

    const {fetchLobby} = useLobby();

    // const userState = IS_LOGGED_IN ? "LI" : "LO";
    // const {storedValue: storedSmartLobby} = useStorage(`smartLobby${userState}`, null, "localStorage");

    useEffect(() => {
        try {
            (async () => {
                if (await supportsWebP) {
                    document.body.classList.add("webp-supported");
                } else {
                    document.body.classList.add("webp-not-supported");
                }
            })()
        } catch (e) {

        }
    }, [])

    useEffect(() => {
        if (!props.hasError) {
            initCalls();
        }
    }, [props.hasError]);

    const initCalls = async () => {
        if (IS_LOGGED_IN) {
            refreshAccount();
            refreshPromotions();
            fetchLobby();
        }
    }

    if (!themeReady) return null;

    if (props.hasError) {
        localStorage.clear();
        sessionStorage.clear();

        return (
            <MuiThemeProvider theme={appliedTheme}>
                <CssBaseline/>
                <div style={{whiteSpace: 'pre-wrap'}} dangerouslySetInnerHTML={{__html: props.error}}/>
                <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                    <h1>😔<br/>We've hit a glitch!</h1>
                    <Typography style={{maxWidth: '450px'}}>
                        Try refreshing the page. If the problem persists, get in touch with our Customer Support team.</Typography>
                </div>
            </MuiThemeProvider>
        );
    }

    return (
        <MuiThemeProvider theme={appliedTheme}>
            <CssBaseline/>
            <SnackbarProvider>
                <Router>
                    {IS_LOGGED_IN && <LazyBedeNotifications/>}
                    <Routes/>
                </Router>
            </SnackbarProvider>
        </MuiThemeProvider>
    );
};

export default App;
