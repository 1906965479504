// Bingo Themes
const butlersBingo = async () => await import(/* webpackChunkName: "themes/butlersBingo" */ `Theme/butlersBingo`).then(theme => theme.default);
const bingoDiamond = async () => await import(/* webpackChunkName: "themes/bingoDiamond" */ `Theme/bingoDiamond`).then(theme => theme.default);
const dottyBingo = async () => await import(/* webpackChunkName: "themes/dottyBingo" */ `Theme/dottyBingo`).then(theme => theme.default);
const glossyBingo = async () => await import(/* webpackChunkName: "themes/glossyBingo" */ `Theme/glossyBingo`).then(theme => theme.default);
const rosyBingo = async () => await import(/* webpackChunkName: "themes/rosyBingo" */ `Theme/rosyBingo`).then(theme => theme.default);

// Casino Themes
const casinoFantastico = async () => await import(/* webpackChunkName: "themes/casinoFantastico" */ `Theme/casinoFantastico`).then(theme => theme.default);
const casinoOfDreams = async () => await import(/* webpackChunkName: "themes/casinoOfDreams" */ `Theme/casinoOfDreams`).then(theme => theme.default);
const lucky247 = async () => await import(/* webpackChunkName: "themes/lucky247" */ `Theme/lucky247`).then(theme => theme.default);

export const THEMES = {
    cf: casinoFantastico,
    cd: casinoOfDreams,
    bb: butlersBingo,
    bd: bingoDiamond,
    db: dottyBingo,
    gb: glossyBingo,
    l247: lucky247,
    rb: rosyBingo
};
