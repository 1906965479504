import {UPDATE_RG_CONFIG} from "Redux/actions/responsibleGambling";

const initialState = {
    isLoaded: false,
    config: null
};

const responsibleGamblingReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_RG_CONFIG:
            return {...state, isLoaded: true, config: action.newConfig};
        default:
            return {...state};
    }
};

export default responsibleGamblingReducer;
