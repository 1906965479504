// Action Types
export const UPDATE_MORPHEUS_SESSION = "UPDATE_MORPHEUS_SESSION";
export const UPDATE_ACCOUNT_LITE_ACTIVE = "UPDATE_ACCOUNT_LITE_ACTIVE";
export const UPDATE_USER_COMMUNICATION_PREFERENCES = "UPDATE_USER_COMMUNICATION_PREFERENCES";
export const UPDATE_HAS_DEPOSITED = "UPDATE_HAS_DEPOSITED";
export const UPDATE_HAS_QUICK_BANKING = "UPDATE_HAS_QUICK_BANKING";

// Action Creator
// export const updateMorpheusSession = (newMorpheusSession) => {
//     console.log(newMorpheusSession);
//
//     return {
//         type: UPDATE_MORPHEUS_SESSION,
//         newMorpheusSession,
//     }
// };

export const updateAccountLiteActive = (isActive) => {
    return {
        type: UPDATE_ACCOUNT_LITE_ACTIVE,
        isActive,
    }
};

export const updateUserCommunicatonPreferences = (newComms) => {
    return {
        type: UPDATE_USER_COMMUNICATION_PREFERENCES,
        newComms,
    }
};

export const setHasDeposited = () => {
    return {
        type: UPDATE_HAS_DEPOSITED,
    }
};

export const setHasQuickBanking = (quickBanking) => {
    return {
        type: UPDATE_HAS_QUICK_BANKING,
        quickBanking
    }
};
