import {Box, makeStyles, Paper, Typography} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import {BP_MEDIUM} from "Theme/breakpoints";

export const useGenericPageContainerStyles = makeStyles(theme => ({
    centerColumn: {
        margin: "0 auto",
        [theme.breakpoints.down(BP_MEDIUM)]: {
            width: "100%",
        }
    },
    paper: {
        padding: theme.spacing(4, 2),
        color: theme.palette.neutral.dark,
        border: 'none',
        borderRadius: 0,
        boxShadow: 'none',
        background: '#FFFFFF',
        [theme.breakpoints.up(BP_MEDIUM)]: {
            padding: theme.spacing(4, 1),
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(8),
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24)',
            borderRadius: theme.shape.borderRadius
        },
        '& h3': {
            fontSize: '1.5rem',
            marginTop: '0',
            [theme.breakpoints.up(BP_MEDIUM)]: {
                fontSize: '2rem',
                textAlign: 'center'
            }
        },
        '& h2': {
            fontSize: '1rem',
            [theme.breakpoints.up(BP_MEDIUM)]: {
                fontSize: '1.5rem',
            }
        },
        '& p h2, .MuiTypography-h5': {
            fontSize: '1rem',
        },
        '& p .subheader': {
            fontSize: '1rem',
        },
        '& img': {
            width: '100%'
        },
        '& .MuiTypography-body1': {
            fontSize: '0.875rem'
        }
    },
    title: {
        textAlign: "center",
        '&.MuiTypography-gutterBottom': {
            marginBottom: theme.spacing(4)
        }
    },
    subheader: {
        marginTop: theme.spacing(4),
        '&.MuiTypography-gutterBottom': {
            marginBottom: theme.spacing(2)
        }
    },
    paragraph: {
        textAlign: 'justify',

        '&.MuiTypography-gutterBottom': {
            marginBottom: "1em"
        },
    },
    button: {
        margin: theme.spacing(2, 'auto', 5),
        display: "flex",
        justifyContent: "center"
    }
}))

export const GenericPageContainer = ({title, children, isReady = true, customHeaderTagVariant = "h4", columnOverrideMedium = 11, columnOverrideXlarge = 8}) => {
    const classes = useGenericPageContainerStyles();

    if (!isReady) {
        return (
            <section className="row">
                <div className={clsx("medium-10", classes.centerColumn)}>
                </div>
            </section>
        );
    }

    return (
        <section className="row">
            <div className={clsx("medium-10", classes.centerColumn)}>
                <Paper className={classes.paper}>
                    <div className={clsx("column", `medium-${columnOverrideMedium} xlarge-${columnOverrideXlarge}`, classes.centerColumn)}>
                        {title && <Typography component="h2" variant={customHeaderTagVariant} className={classes.title} gutterBottom={true}>{title}</Typography>}
                        <Box>
                            {children}
                        </Box>
                    </div>
                </Paper>
            </div>
        </section>
    );
};

export const Subheader = ({children, className}) => {
    const classes = useGenericPageContainerStyles();

    return (
        <Typography component="h2" variant="h4" className={clsx(classes.title, classes.subheader, className)} gutterBottom={true}>{children}</Typography>
    )
};

export const Paragraph = ({children}) => {
    const classes = useGenericPageContainerStyles();

    return (
        <Typography className={clsx(classes.paragraph)} gutterBottom={true}>{children}</Typography>
    )
};
