const RouteLoadingSplash = () => {
    // React.useEffect(() => {
    //     console.log("hello")
    //
    //     if (document.getElementById('app').classList.contains('loaded')) {
    //         document.getElementById('app').classList.remove('loaded');
    //     }
    //
    //     return () => {
    //         if (!document.getElementById('app').classList.contains('loaded')) {
    //             document.getElementById('app').classList.add('loaded');
    //         }
    //     };
    // }, []);

    return null;
};

export default RouteLoadingSplash;
