import React from "react";
import {LazyLoad, LazyLoaderFactory} from "Containers/lazyRoutes";

const BedeNotificationsLazyLoadComponent = () => {
    return (
        <div/>
    )
}

const BedeNotifications = LazyLoaderFactory(() => import(/* webpackChunkName: "LoggedInBundledComponents" */ "Components/BedeComponents/BedeNotifications"), () =>
    <BedeNotificationsLazyLoadComponent/>);
export const LazyBedeNotifications = props => <LazyLoad isRoute={false} component={BedeNotifications} {...props}/>;
