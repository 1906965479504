const liveAgentReducer = (state = {isLoaded: false}, action) => {
    switch (action.type) {
        case 'UPDATE_LIVE_AGENT_STATUS':
            return Object.assign({}, state, {
                isLoaded: action.isLoaded
            });
        default:
            return state;
    }
};

export default liveAgentReducer;
